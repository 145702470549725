class ValidationRequest {
  version: string;
  productUID: string;
  productNumber: string;
  nfcUID: string;
  tapCounter: string;
  checkSum: string;

  constructor(
    version: string,
    producUID: string,
    productNumber: string,
    nfcUID: string,
    tapCounter: string,
    checkSum: string
  ) {
    this.version = version;
    this.productUID = producUID;
    this.productNumber = productNumber;
    this.nfcUID = nfcUID;
    this.tapCounter = tapCounter;
    this.checkSum = checkSum;
  }

  isEmpty(): boolean {
    return (
      this.version === "" &&
      this.productUID === "" &&
      this.productNumber === "" &&
      this.nfcUID === "" &&
      this.tapCounter === "" &&
      this.checkSum === ""
    );
  }

  isValid(): boolean {
    return (
      this.version !== "" &&
      this.productUID !== "" &&
      this.productNumber !== "" &&
      this.nfcUID !== "" &&
      this.tapCounter !== "" &&
      this.checkSum !== ""
    );
  }

  static fromURLParams(urlParams: URLSearchParams): ValidationRequest {
    const version = urlParams.get("v");
    const productUID = urlParams.get("sn");
    const productNumber = urlParams.get("pd");
    const nfcUID = urlParams.get("u");
    const tapCounter = urlParams.get("c");
    const checkSum = urlParams.get("m");
    if (
      version !== null &&
      productUID !== null &&
      productNumber !== null &&
      nfcUID !== null &&
      tapCounter !== null &&
      checkSum !== null
    )
      return new ValidationRequest(
        version,
        productUID,
        productNumber,
        nfcUID,
        tapCounter,
        checkSum
      );
    else return new ValidationRequest("", "", "", "", "", "");
  }

  static validateURLParams(urlParams: URLSearchParams): boolean {
    return (
      !this.isNullOrEmpty(urlParams.get("v")) &&
      !this.isNullOrEmpty(urlParams.get("sn")) &&
      !this.isNullOrEmpty(urlParams.get("pd")) &&
      !this.isNullOrEmpty(urlParams.get("u")) &&
      !this.isNullOrEmpty(urlParams.get("c")) &&
      !this.isNullOrEmpty(urlParams.get("m"))
    );
  }
  private static isNullOrEmpty(value: string | null): boolean {
    return value === null || value.trim() === "";
  }
}

export { ValidationRequest };
