import React from "react";
import "./ProductParameterList.css";
import ProductParameter from "./ProductParameter.tsx";

export interface ProductParameterItem {
  heading: string;
  name: string;
  details: string;
}

interface Props {
  isLoading: boolean;
  items?: ProductParameterItem[];
  failed?: boolean;
}

const ProductParameterList: React.FC<Props> = ({
  isLoading,
  items,
  failed,
}) => {
  return (
    <ul className="product-parameter-list" hidden={failed}>
      {items?.map((item) => (
        <li key={item.heading}>
          <ProductParameter
            isLoading={isLoading}
            failed={failed}
            heading={item.heading}
            name={item.name}
            details={item.details}
          />
        </li>
      ))}
    </ul>
  );
};
export default ProductParameterList;
