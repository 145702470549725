import React from "react";

import ProgressIndicator from "./ProgressIndicator.tsx";
import "./AppMessage.css";

import { ValidationState } from "../../data/ValidationResponse.ts";
import { cookieService } from "../../service/CookieService.ts";

interface Props {
  isLoading: boolean;
  state: ValidationState;
  heading: string;
  content: string;
  hasReloaded: boolean;
}

const AppMessage: React.FC<Props> = ({
  isLoading,
  state,
  heading,
  content,
  hasReloaded,
}) => {
  //Constants

  //States
  function getClassFromState(state: ValidationState, hasReloaded: boolean) {
    switch (state) {
      case ValidationState.Success:
        return "success";
      case ValidationState.Interrupted:
        return "interrupted";
      case ValidationState.Failed:
        if (hasReloaded) return "info";
        else return "failed";
      default:
        return "";
    }
  }
  //Effect Hook

  //Return component
  return (
    <div className="app-message">
      <ProgressIndicator
        isLoading={isLoading}
        state={state}
        hasReloaded={hasReloaded}
      />
      {isLoading ? (
        <>
          <h1 className={"message-heading primary"}>{heading}</h1>
          <p className={"message-content secondary"}>{content}</p>
        </>
      ) : (
        <>
          <h1
            className={
              "message-heading " + getClassFromState(state, hasReloaded)
            }
          >
            {heading}
          </h1>
          <p
            className={
              "message-content " + getClassFromState(state, hasReloaded)
            }
          >
            {content}
          </p>
        </>
      )}
    </div>
  );
};

export default AppMessage;
