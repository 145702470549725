import React from "react";
import "./NavBarComponent.css";

interface Props {
  logo: string;
}

const NavBarComponent: React.FC<Props> = ({ logo }) => {
  return (
    <div className="navbar">
      <div className="navbar-logo">
        <img src={logo} alt="Logo" />
      </div>
    </div>
  );
};

export default NavBarComponent;
