import React from "react";

import "./AppOverlay.css";
import AppMessage from "./elements/AppMessage.tsx";

import { ValidationState } from "../data/ValidationResponse.ts";
import AppMessageDetails from "./elements/AppMessageDetails.tsx";

import { cookieService } from "../service/CookieService.ts";
import { ValidationRequest } from "../data/ValidationRequest.ts";

interface Props {
  isLoading: boolean;
  state: ValidationState;
  hasReloaded: boolean;
}

const AppOverlay: React.FC<Props> = ({ isLoading, state, hasReloaded }) => {
  //Constants

  //States

  //Effect Hook

  //Render overlay
  function renderOverlay(isLoading: boolean, state: ValidationState) {
    switch (state) {
      case ValidationState.Success:
        return isLoading ? "blur" : "";
      case ValidationState.Interrupted:
        return "blur";
      case ValidationState.Failed:
        return "blur";
      default:
        return "";
    }
  }

  //Render message details
  function getMessageDetails(state: ValidationState, hasReloaded: boolean) {
    switch (state) {
      case ValidationState.Failed:
        if (hasReloaded)
          return renderFailedMessageDetails(
            "TWERiFY detected a reload of the page.\n\nDue to the design of the system the received URL is only valid once. To ensure your product is an original, please scan the NFC tag again."
          );
        else
          return renderFailedMessageDetails(
            "Possibly there are problems with the connection to the verification system, the internet connection was interrupted or there is some other technical error.\n\n" +
              "Please try again later."
          );
      case ValidationState.Interrupted:
        return renderInterruptedMessageDetails(
          "Possibly there are problems with the connection to the verification system, the internet connection was interrupted or there is some other technical error.\n\n" +
            "Please try again later."
        );
      default:
        return null;
    }
  }
  function renderFailedMessageDetails(message: string) {
    return <AppMessageDetails content={message} />;
  }
  function renderInterruptedMessageDetails(message: string) {
    return <AppMessageDetails content={message} />;
  }

  //Get text
  function getHeadingFromStatus(state: ValidationState) {
    switch (state) {
      case ValidationState.Success:
        return "VERIFIED";
      case ValidationState.Interrupted:
        return "OUCH";
      case ValidationState.Failed:
        return "VERIFICATION FAILED";
      default:
        return "";
    }
  }
  function getMessageFromStatus(state: ValidationState, hasReloaded: boolean) {
    switch (state) {
      case ValidationState.Success:
        return "THE SCANNED LOUDSPEAKER IS A GENUINE TW AUDiO PRODUCT.";
      case ValidationState.Interrupted:
        return "SOMETHING WENT WRONG.";
      case ValidationState.Failed:
        if (hasReloaded) return "RELOAD DETECTED.";
        else return "VERIFIFACTION FAILED.";
      default:
        return "";
    }
  }
  //Return component
  return (
    <div className={"overlay " + renderOverlay(isLoading, state)}>
      <AppMessage
        isLoading={isLoading}
        state={state}
        heading={getHeadingFromStatus(state)}
        content={getMessageFromStatus(state, hasReloaded)}
        hasReloaded={hasReloaded}
      />
      {getMessageDetails(state, hasReloaded)}
    </div>
  );
};

export default AppOverlay;
