import React, { useEffect, useState } from "react";

//UI Components
import NavBarComponent from "./NavBarComponent.tsx";
import AppContent from "./AppContent.tsx";
import FooterComponent from "./FooterComponent.tsx";

//Functionallity
import ValidationService from "../service/ValidationService.ts";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  ValidationResponse,
  ValidationState,
} from "../data/ValidationResponse.ts";
import { ValidationRequest } from "../data/ValidationRequest.ts";
import { CanceledError } from "axios";
import { cookieService } from "../service/CookieService.ts";
import { configurationService } from "../service/ConfigurationService.ts";

//Properties
interface Props {}

//Return component
const AppLayout: React.FC<Props> = () => {
  //Constants
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const footerLinks = [
    { name: "contact", href: "https://twaudio.de/en/contact/" },
    {
      name: "imprint",
      href: "https://twaudio.de/contact/imprint-legal-notice/",
    },
    {
      name: "privacy policy",
      href: "https://twaudio.de/contact/privacy-policy/",
    },
  ];
  //States
  const [response, setResponse] = useState<ValidationResponse>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [hasReloaded, setHasReloaded] = useState(false);

  //Effect Hook
  useEffect(() => {
    if (!redirect)
      setRedirect(
        !ValidationRequest.validateURLParams(
          new URLSearchParams(location.search)
        )
      );
  }, [location]);
  useEffect(() => {
    if (redirect) {
      const redirectUrl =
        configurationService.get("REACT_APP_REDIRECT_URL") ||
        "https://twaudio.de/en/";
      window.location.href = redirectUrl;
    } else {
      //Verify URL parameters
      const reqBody = ValidationRequest.fromURLParams(searchParams);

      //Evaluate validation response
      if (process.env.NODE_ENV === "development") {
        setTimeout(() => {
          const res = ValidationResponse.simulateValidResponse("NFCDummy");
          if (res != null) {
            setResponse(res);
            cookieService
              .getCookie()
              .then((cookieContent) => {
                if (
                  cookieContent === "" &&
                  res.status === ValidationState.Success
                ) {
                  cookieService.setCookie(JSON.stringify(res), 5);
                } else if (cookieContent !== "") {
                  const cookieObject = JSON.parse(cookieContent);
                  if (cookieObject.status === ValidationState.Success) {
                    setHasReloaded(true);
                  }
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            console.error("Error. The requested response has failed.");
          }
        }, 3000);
      } else {
        const request = ValidationService.post<
          ValidationRequest,
          ValidationResponse
        >(reqBody);
        request
          .then((res) => {
            setResponse(res.data);
            cookieService
              .getCookie()
              .then((cookieContent) => {
                if (
                  cookieContent === "" &&
                  res.data.status === ValidationState.Success
                ) {
                  cookieService.setCookie(JSON.stringify(res.data), 5);
                } else if (cookieContent !== "") {
                  const cookieObject = JSON.parse(cookieContent);
                  if (cookieObject.status === ValidationState.Success) {
                    setHasReloaded(true);
                  }
                }
                setIsLoading(false);
              })
              .catch((err) => {
                console.error(err);
                setIsLoading(false);
              });
          })
          .catch((err) => {
            if (err instanceof CanceledError) return;
            console.error(err);
            setResponse(ValidationResponse.createInterruptedResponse());
            setIsLoading(false);
          });
      }
    }
  }, [redirect]);

  //Return component
  return (
    <>
      <NavBarComponent logo="/twaudio_dark.png" />
      {redirect ? (
        <p></p>
      ) : (
        <AppContent
          validationResponse={response || undefined}
          isLoading={isLoading}
          hasReloaded={hasReloaded}
        />
      )}
      <FooterComponent links={footerLinks} />
    </>
  );
};

export default AppLayout;
