import React from "react";

import { ReactComponent as LogoDark } from "../assets/twerify_dark.svg";
import AppHeaderElement from "./elements/AppHeaderElement.tsx";
import AppOverlay from "./AppOverlay.tsx";
import ProductImage from "./elements/ProductImage.tsx";
import ProductParameterList, {
  ProductParameterItem,
} from "./elements/ProductParameterList.tsx";
import {
  ValidationResponse,
  ValidationState,
} from "../data/ValidationResponse.ts";

interface Props {
  validationResponse: ValidationResponse | undefined;
  isLoading: boolean;
  hasReloaded: boolean;
}

const AppContent: React.FC<Props> = ({ validationResponse, isLoading, hasReloaded }) => {
  //Constants
  let items: ProductParameterItem[] = [
    {
      heading: "Serial Number",
      name: validationResponse?.serialNumber || "",
      details: "",
    },
    {
      heading: "Product Name",
      name: validationResponse?.productName || "",
      details: "",
    },
    {
      heading: "Article Number",
      name: validationResponse?.productNumber || "",
      details: "",
    },
  ];

  //States

  //Effect Hook

  //Return component
  return (
    <div className="content">
      <AppHeaderElement
        Logo={LogoDark}
        primaryText="THE OFFICIAL PRODUCT VERIFICATION PROCESS"
        secondaryText="BY TW AUDiO"
      />
      <AppOverlay
        isLoading={isLoading}
        state={validationResponse?.status || ValidationState.Unknown}
        hasReloaded={hasReloaded}
      />
      <ProductImage
        isLoading={isLoading}
        source={validationResponse?.imageUrl}
        failed={
          validationResponse?.status === ValidationState.Failed ||
          validationResponse?.status === ValidationState.Interrupted
        }
      />
      <ProductParameterList
        isLoading={isLoading}
        items={items}
        failed={
          validationResponse?.status === ValidationState.Failed ||
          validationResponse?.status === ValidationState.Interrupted
        }
      />
    </div>
  );
};

export default AppContent;
