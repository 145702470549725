import React from "react";
import "./ProductParameter.css";
import checkBox from "../../assets/checkbox.svg";
import failedBox from "../../assets/failedbox.svg";
import smallLoader from "../../assets/small_loader.svg";

interface Props {
  isLoading: boolean;
  failed?: boolean;
  heading: string;
  name: string;
  details: string;
}

const ProductParameter: React.FC<Props> = ({
  isLoading,
  failed = false,
  heading,
  name,
  details,
}) => {
  function getClassIfLoading(loading: boolean) {
    return loading ? "loading" : "";
  }
  function getCheckbox(loading: boolean, failed: boolean) {
    if (isLoading) return <img src={smallLoader} alt="Loader" />;
    if (!isLoading && failed) return <img src={failedBox} alt="Failed" />;
    else return <img src={checkBox} alt="Checkbox" />;
  }
  return (
    <div className="product-parameter">
      <div className="product-info">
        <div
          className={
            "product-heading " + getClassIfLoading(isLoading || failed)
          }
        >
          {isLoading || failed ? "#" : heading}
        </div>
        <div
          className={
            "product-name-details " + getClassIfLoading(isLoading || failed)
          }
        >
          <div
            className={"product-name " + getClassIfLoading(isLoading || failed)}
          >
            {isLoading || failed ? "#" : name}
          </div>
          <div className="product-details" hidden={isLoading || failed}>
            {details}
          </div>
        </div>
      </div>
      <div className="product-check" hidden={failed}>
        {getCheckbox(isLoading, failed)}
      </div>
    </div>
  );
};
export default ProductParameter;
