import { ValidationState } from "../data/ValidationResponse";

class CookieService {
  async setCookie(value: string, minutes: number): Promise<void> {
    const hashedName = await this.getHashedName();
    const now = new Date();
    now.setTime(now.getTime() + minutes * 60 * 1000);
    const expires = "expires=" + now.toUTCString();
    document.cookie = `${hashedName}=${value};${expires};path=/`;
  }
  async doesCookieExist(): Promise<boolean> {
    const hashedName = await this.getHashedName();
    const nameEQ = hashedName + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return true;
    }
    return false;
  }

  async getCookie(): Promise<string> {
    const hashedName = await this.getHashedName();
    const nameEQ = hashedName + "=";
    const ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return "";
  }

  private async getHashedName(): Promise<string> {
    return await computeSHA256(window.location.toString());
  }
}

async function computeSHA256(message: string): Promise<string> {
  const msgBuffer = new TextEncoder().encode(message);
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

export const cookieService = new CookieService();
