import React from "react";
import "./ProductImage.css";

interface Props {
  isLoading: boolean;
  failed?: boolean;
  source?: string;
}

const ProductImage: React.FC<Props> = ({
  isLoading,
  failed = false,
  source,
}) => {
  return (
    <div className="product-image">
      <img src={source} alt="Product" hidden={isLoading || failed} />
    </div>
  );
};

export default ProductImage;
