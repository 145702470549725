import React from "react";
import "./FooterComponent.css";

type FooterElement = {
  name: string;
  href: string;
};

interface Props {
  links: FooterElement[];
}
const FooterComponent: React.FC<Props> = ({ links }) => {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer-content">
          {links.map((link, index) => (
            <React.Fragment key={link.name}>
              {index !== 0 && <span className="separator">|</span>}
              <a href={link.href} className="footer-link">
                {link.name}
              </a>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
