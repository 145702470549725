import React, { ReactComponentElement } from "react";
import "./AppHeaderElement.css";

interface Props {
  Logo: React.ComponentType;
  primaryText: string;
  secondaryText: string;
}

const AppHeaderElement: React.FC<Props> = ({
  Logo,
  primaryText,
  secondaryText,
}) => {
  return (
    <div className="app-header">
      <div className="app-logo">
        <Logo />
      </div>
      <div className="app-texts">
        <p className="primary-text">{primaryText}</p>
        <p className="secondary-text">{secondaryText}</p>
      </div>
    </div>
  );
};

export default AppHeaderElement;
