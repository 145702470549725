import React from "react";

import "./AppMessageDetails.css";

interface Props {
  content: string;
}

const AppMessageDetails: React.FC<Props> = ({ content }) => {
  //Constants

  //States

  //Effect Hook

  //Return component
  return (
    <div className="app-message-details">
      <p>
        {content.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
};

export default AppMessageDetails;
