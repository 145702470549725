export enum ValidationState {
  Unknown = "UNKNOWN",
  Success = "SUCCESS",
  Interrupted = "INTERRUPTED",
  Failed = "FAILED",
}

class ValidationResponse {
  status: ValidationState;
  productName: string;
  productNumber: string;
  serialNumber: string;
  imageUrl: string;
  productUrl: string;

  constructor(
    status: ValidationState,
    productName: string,
    productNumber: string,
    serialNumber: string,
    imageUrl: string,
    productUrl: string
  ) {
    this.status = status;
    this.productName = productName;
    this.productNumber = productNumber;
    this.serialNumber = serialNumber;
    this.imageUrl = imageUrl;
    this.productUrl = productUrl;
  }

  static fromJSON(json: string): ValidationResponse {
    const data = JSON.parse(json);

    return new ValidationResponse(
      data.status,
      data.productName,
      data.productNumber,
      data.serialNumber,
      data.imageUrl,
      data.productUrl
    );
  }

  static createInterruptedResponse() {
    return new ValidationResponse(
      ValidationState.Interrupted,
      "Unknown",
      "",
      "",
      "",
      ""
    );
  }

  static simulateInvalidResponse(product: string): ValidationResponse | null {
    if (product === "B30")
      return new ValidationResponse(
        ValidationState.Failed,
        product,
        "2064-003",
        "23021444",
        "",
        ""
      );
    else if (product === "C5")
      return new ValidationResponse(
        ValidationState.Failed,
        product,
        "2569",
        "23021744",
        "",
        ""
      );
    else if (product === "Ella6")
      return new ValidationResponse(
        ValidationState.Failed,
        product,
        "5489-004",
        "23021444",
        "",
        ""
      );
    else if (product === "EllaL18")
      return new ValidationResponse(
        ValidationState.Failed,
        product,
        "5489-004",
        "23021444",
        "",
        ""
      );
    else if (product === "NFCDummy")
      return new ValidationResponse(
        ValidationState.Failed,
        product,
        "2569",
        "23021744",
        "",
        ""
      );
    else {
      return null;
    }
  }

  static simulateValidResponse(product: string): ValidationResponse | null {
    if (product === "B30")
      return new ValidationResponse(
        ValidationState.Success,
        product,
        "2064-003",
        "23021444",
        "https://files.it.twaudio.de/twerify/B30.png",
        "https://twaudio.de/product/b-serie/b30-erfolgreiche-spitzenleistung/"
      );
    else if (product === "Ella6")
      return new ValidationResponse(
        ValidationState.Success,
        product,
        "5489-004",
        "23021444",
        "https://files.it.twaudio.de/twerify/ELLA6.png",
        "https://twaudio.de/product/ella-i/ella6-das-elegante-line-array/"
      );
    else if (product === "EllaL18")
      return new ValidationResponse(
        ValidationState.Success,
        product,
        "5489-004",
        "23021444",
        "https://files.it.twaudio.de/twerify/ELLAL18.png",
        "https://twaudio.de/product/ella-i/ella-l18-die-elegante-erweiterung/"
      );
    else if (product === "C5")
      return new ValidationResponse(
        ValidationState.Success,
        product,
        "2569",
        "23021744",
        "https://files.it.twaudio.de/twerify/C5.png",
        "https://twaudio.de/product/c-serie/c5-kleinlaut-reloaded/"
      );
    else if (product === "NFCDummy")
      return new ValidationResponse(
        ValidationState.Success,
        product,
        "2569",
        "23021744",
        "https://files.it.twaudio.de/twerify/NFCDummy.png",
        ""
      );
    else return null;
  }
}

export { ValidationResponse };
