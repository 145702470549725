import axios, { CanceledError } from "axios";
import { configurationService } from "./ConfigurationService";
export default axios.create({
  baseURL: configurationService.get("REACT_APP_API_URL"),
  headers: {
    "X-API-Key": configurationService.get("REACT_APP_API_KEY"),
    "Content-Type": "application/json",
  },
});
export { CanceledError };
