import APIClient from "./APIClient";
import { AxiosResponse } from "axios";

class HTTPService {
  endpoint: string;
  constructor(endpoint: string) {
    this.endpoint = endpoint;
  }
  post<TRequest, TResponse>(
    data: TRequest,
    config = { timeout: 5000 }
  ): Promise<AxiosResponse<TResponse>> {
    try {
      const headers = { "Content-Type": "application/json" };
      const response = APIClient.post<TResponse>(this.endpoint, data, {
        ...config,
        headers,
      });
      return response;
    } catch (error) {
      throw error;
    }
  }
}
const create = (endpoint: string) => new HTTPService(endpoint);
export default create;
