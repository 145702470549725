import React from "react";
import "./ProgressIndicator.css";

import successSymbol from "../../assets/verified.svg";
import interruptedSymbol from "../../assets/interrupted.svg";
import reloadedSymbol from "../../assets/reload.svg";
import failedSymbol from "../../assets/failed.svg";
import bigLoader from "../../assets/big_loader.svg";

import { ValidationState } from "../../data/ValidationResponse.ts";

interface Props {
  isLoading: boolean;
  state: ValidationState;
  hasReloaded: boolean;
}

const ProgressIndicator: React.FC<Props> = ({
  isLoading,
  state,
  hasReloaded,
}) => {
  function getSymbolByState(state: ValidationState, hasReloaded: boolean) {
    switch (state) {
      case ValidationState.Success:
        return (
          <img
            src={successSymbol}
            alt="Success"
            className="progress-indicator"
          />
        );
      case ValidationState.Interrupted:
        return (
          <img
            src={interruptedSymbol}
            alt="Interrupted"
            className="progress-indicator"
          />
        );
      default:
        if (hasReloaded)
          return (
            <img
              src={reloadedSymbol}
              alt="Reload"
              className="progress-indicator"
            />
          );
        else
          return (
            <img
              src={failedSymbol}
              alt="Failed"
              className="progress-indicator"
            />
          );
    }
  }
  return (
    <div className="progress-container">
      {isLoading ? (
        <img src={bigLoader} alt="Loader" className="progress-indicator" />
      ) : (
        getSymbolByState(state, hasReloaded)
      )}
    </div>
  );
};

export default ProgressIndicator;
